import request from '../request.js'

export default {
    companyCompanyList(_data) { // 企业列表
        return request({
            url: '/api/company/companyList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companySaveOrUpdateCompany(_data) { // 添加修改企业
        return request({
            url: '/api/company/saveOrUpdateCompany?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    companyCompanyDetail(_data) { // 企业详情
        return request({
            url: '/api/company/companyDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companyLogList(_data) { // 生成记录
        return request({
            url: '/api/company/logList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companyInsertLog(_data) { // 生成洗车券
        return request({
            url: '/api/company/insertLog?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    companyLogDetail(_data) { // 生成记录详情
        return request({
            url: '/api/company/logDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companyCodeList(_data) { // 券码列表
        return request({
            url: '/api/company/codeList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companyVerificationList(_data) { // 核销记录
        return request({
            url: '/api/company/verificationList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    companyCodeUpdate(_data) { // 券码修改
        return request({
            url: '/api/company/codeUpdate?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    companyGetZip(_data) { // 下载记录
        return request({
            url: '/api/company/getZip?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },
    companyExportVerificationList(_data) { // 导出核销记录
        return request({
            url: '/api/company/exportVerificationList?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },
}