import request from '../request.js'

export default {
    informationList(_data) { // 二手车咨询列表
        return request({
            url: '/api/information/informationList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    saveOrUpdateInformation(_data) { // 二手车添加
        return request({
            url: '/api/information/saveOrUpdateInformation?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    deleteInformation(_data) { // 二手车删除
        return request({
            url: '/api/information/deleteInformation?data=' + JSON.stringify(_data),
            method: 'post',
        })
    }
}