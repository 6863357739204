import request from '../request.js'

export default {
    couponList(_data) { // 优惠券列表
        return request({
            url: '/api/coupon/couponList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    saveCoupon(_data) { // 添加优惠券
        return request({
            url: '/api/coupon/saveCoupon?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    updateCouponStatus(_data) { // 优惠券状态
        return request({
            url: '/api/coupon/updateCouponStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    couponDetail(_data) { // 优惠券详情
        return request({
            url: '/api/coupon/couponDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    userCouponList(_data) { // 优惠券使用信息
        return request({
            url: '/api/coupon/userCouponList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    userCouponShopList(_data) { // 开单券门店信息
        return request({
            url: '/api/coupon/userCouponShopList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
}