import request from '../request.js'

export default {
    carWasherList(_data) { // 洗衣机列表
        return request({
            url: '/api/carWasher/carWasherList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    saveOrUpdateCarWasher(_data) { // 修改保存洗车机
        return request({
            url: '/api/carWasher/saveOrUpdateCarWasher?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    carWasherDetail(_data) { // 洗车机详情
        return request({
            url: '/api/carWasher/carWasherDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    carWasherBillList(_data) { // 洗车机账单
        return request({
            url: '/api/carWasher/carWasherBillList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    
}