import request from '../request.js'

export default {
    updateVipShop(_data) { // 会员卡下架可用门店
        return request({
            url: '/api/vip/updateVipShop?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    vipShop(_data) { // vip门店列表
        return request({
            url: '/api/vip/vipShop?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    saveCardOne(_data) { // 卡号卡密添加
        return request({
            url: '/api/vip/saveCardOne?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    cardOne(_data) { // 卡号卡密添加
        return request({
            url: '/api/vip/cardOne?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    cardTwo(_data) { // 二级卡密查询
        return request({
            url: '/api/vip/cardTwo?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    updateCardOne(_data) { // 停用卡密一级
        return request({
            url: '/api/vip/updateCardOne?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    updateCardTwo(_data) { // 停用卡密二级
        return request({
            url: '/api/vip/updateCardTwo?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    
    exportCardTwo(_data) { // 卡密二级
        return request({
            url: '/api/vip/exportCardTwo?data=' + JSON.stringify(_data),
            method: 'post',
            responseType: 'blob'
        })
    },
    userVipOrderRefund(_data) { // 会员卡回退订单
        return request({
            url: '/system/userVipOrder/refund?data=' + JSON.stringify(_data),
            method: 'post'
        })
    },
    userFreezeUserVip(_data) { // 冻结会员卡
        return request({
            url: '/api/user/freezeUserVip?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
}