import request from './request.js'

const modulesFiles = require.context('./apiModule', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	return Object.assign(modules, modulesFiles(modulePath).default)
}, {})

export default {
    ...modules,
    /**
     * 第三期添加扩展
     */
    uploadMultipartFile(_data) { //上传文件
        return request({
            url: '/api/upload/uploadMultipartFile',
            method: 'post',
            data: _data,
        })
    },


    // 业务员管理接口

    deleteShopStaff(_data) { //业务员审核删除
        return request({
            url: '/api/staff/deleteShopStaff?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getShopStaffAllList(_data) { //查询业务员列表
        return request({
            url: '/api/staff/getShopStaffAllList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getAdminShopStaffDetails(_data) { //查询业务员详情
        return request({
            url: '/api/staff/getAdminShopStaffDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopStaffExamineList(_data) { //查询业务员审核列表
        return request({
            url: '/api/staff/getShopStaffExamineList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateShopStaffStatus(_data) { //业务员审核通过-拒绝
        return request({
            url: '/api/staff/updateShopStaffStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    freezeAndRestoreStaff(_data) { //业务员审核通过-拒绝
        return request({
            url: '/api/staff/freezeAndRestoreStaff?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    getAdminStaffSellingCardsInfo(_data) { //业务员售卡数据统计
        return request({
            url: '/api/staff/getAdminStaffSellingCardsInfo?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },


    // 会员卡管理接口

    addVip(_data) { //添加会员卡
        return request({
            url: '/api/vip/addVip?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getShopServiceTerms() { //服务项
        return request({
            url: '/api/vip/getShopServiceTerms',
            method: 'get',
        })
    },
    getVipGetVipServiceDetail(_data) { //会员卡详情
        return request({
            url: '/api/vip/getVipServiceDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    getVipList(_data) { //查询会员卡列表
        return request({
            url: '/api/vip/getVipList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    getUserVipOrderList(_data) { //查询会员卡列表
        return request({
            url: '/system/userVipOrder/getUserVipOrderList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getVipUserList(_data) { //查询导入会员列表
        return request({
            url: '/api/vip/getVipUserList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    importUserVip(_data) { //导入会员
        return request({
            url: '/api/vip/importUserVip?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateVipStatus(_data) { //会员卡启动-停用
        return request({
            url: '/api/vip/updateVipStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    exportWorkTemplate(_data) { // 会员卡订单-导出
        return request({
            url: '/system/userVipOrder/exportWorkTemplate?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },


    // 数据统计接口

    getDataInformation() { //数据信息
        return request({
            url: '/api/statistics/getDataInformation',
            method: 'get',
        })
    },

    getShopSalesVolumeRanking(_data) { //门店销量排名
        return request({
            url: '/api/statistics/getShopSalesVolumeRanking?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopServiceRanking(_data) { //服务信息排名
        return request({
            url: '/api/statistics/getShopServiceRanking?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getWriteOffOrdersTrendChart(_data) { //核销订单趋势图
        return request({
            url: '/api/statistics/getWriteOffOrdersTrendChart?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },


    // 文章管理接口

    getArticleList(_data) { //文章列表
        return request({
            url: '/api/article/getArticleList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateArticle(_data, html) { //编辑文章
        return request({
            url: '/api/article/updateArticle?data=' + JSON.stringify(_data),
            method: 'post',
            data: html
        })
    },


    // 服务分类接口

    addShopServiceType(_data) { //添加服务分类
        return request({
            url: '/api/shopservicetype/addShopServiceType?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    deleteShopServiceType(_data) { //删除服务分类
        return request({
            url: '/api/shopservicetype/deleteShopServiceType?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getShopServiceType(_data) { //服务类型
        return request({
            url: '/api/shopservicetype/getShopServiceType?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    getShopServiceTypes(_data) { //服务类型
        return request({
            url: '/api/shopservicetype/getShopServiceType1?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopServiceTypeDetails(_data) { //查询服务分类详情
        return request({
            url: '/api/shopservicetype/getShopServiceTypeDetails?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getShopServiceTypeList(_data) { //服务分类列表
        return request({
            url: '/api/shopservicetype/getShopServiceTypeList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateShopServiceType(_data) { //编辑服务分类
        return request({
            url: '/api/shopservicetype/updateShopServiceType?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 服务管理接口

    addShopService(_data) { //添加服务
        return request({
            url: '/api/shopservice/addShopService?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getShopServiceDetails(_data) { //查询服务详情
        return request({
            url: '/api/shopservice/getShopServiceDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopServiceList(_data) { //服务管理列表
        return request({
            url: '/api/shopservice/getShopServiceList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateShopService(_data) { //编辑服务
        return request({
            url: '/api/shopservice/updateShopService?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateShopServiceByStatus(_data) { //服务上下架
        return request({
            url: '/api/shopservice/updateShopServiceByStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    // 消息通知接口

    addMessage(_data, html) { //发布消息
        return request({
            url: '/api/message/addMessage?data=' + JSON.stringify(_data),
            method: 'post',
            data: html
        })
    },

    deleteMessage(_data) { //消息删除
        return request({
            url: '/api/message/deleteMessage?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getMessageList(_data) { //消息列表
        return request({
            url: '/api/message/getMessageList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getPushMessageList(_data) { //推送消息列表
        return request({
            url: '/api/message/getPushMessageList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateMessageTemplate(_data, html) { //编辑推送消息
        return request({
            url: '/api/message/updateMessageTemplate?data=' + JSON.stringify(_data),
            method: 'post',
            data: html
        })
    },


    // 登录接口

    authMobileLogin(_data) { //手机号登录
        return request({
            url: '/api/admin/authMobileLogin?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getAuthCode(_data) { //发送验证码
        return request({
            url: '/api/admin/getAuthCode?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },


    // 门店管理接口

    addShopData(_data) { //添加门店
        return request({
            url: '/api/shopservice/addShopData?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    addShopManager(_data) { //添加店长
        return request({
            url: '/api/shopservice/addShopManager?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    addStaffShopId(_data) { //添加业务员
        return request({
            url: '/api/shopservice/addStaffShopId?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    updateAdminShopData(_data) { //修改门店
        return request({
            url: '/api/shopservice/updateAdminShopData?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    deleteShopData(_data) { //门店资料审核删除
        return request({
            url: '/api/shopservice/deleteShopData?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getDataReviewDetails(_data) { //查询资料审核详情
        return request({
            url: '/api/shopservice/getDataReviewDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getDataReviewList(_data) { //资料审核列表
        return request({
            url: '/api/shopservice/getDataReviewList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopBillServiceList(_data) { //服务记录列表
        return request({
            url: '/api/shopservice/getShopBillServiceList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopUserList(_data) { //查询门店详情店长列表
        return request({
            url: '/api/shopservice/getShopUserList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopDataDetails(_data) { //查询门店详情
        return request({
            url: '/api/shopservice/getShopDataDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopDataList(_data) { //门店列表
        return request({
            url: '/api/shopservice/getShopDataList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopServiceMendianList(_data) { //查询门店详情门店服务列表
        return request({
            url: '/api/shopservice/getShopServiceMendianList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopStaffList(_data) { //查询门店详情业务员列表
        return request({
            url: '/api/shopservice/getShopStaffList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateAllShopDataStatus(_data) { //一键通过-拒绝
        return request({
            url: '/api/shopservice/updateAllShopDataStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateShopDataStatus(_data) { //审核通过-拒绝
        return request({
            url: '/api/shopservice/updateShopDataStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateShopPassword(_data) { //更改密码
        return request({
            url: '/api/shopservice/updateShopPassword?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateShopServiceMendianStatus(_data) { //门店服务上下架
        return request({
            url: '/api/shopservice/updateShopServiceMendianStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    updateShopStatus(_data) { //门店冻结解冻
        return request({
            url: '/api/shopservice/updateShopStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 用户管理接口

    addGiveUserVip(_data) { //给用户添加会员卡
        return request({
            url: '/api/user/addGiveUserVip?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getUserDetails(_data) { //用户详情
        return request({
            url: '/api/user/getUserDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getUserDetailsCarList(_data) { //车辆列表
        return request({
            url: '/api/user/getUserDetailsCarList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getUserDetailsVipList(_data) { //会员卡信息列表
        return request({
            url: '/api/user/getUserDetailsVipList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getUserList(_data) { //用户列表
        return request({
            url: '/api/user/getUserList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getUserVipServiceNumber(_data) { //会员卡剩余服务
        return request({
            url: '/api/user/getUserVipServiceNumber?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getVipNameList(_data) { //会员卡列表
        return request({
            url: '/api/user/getVipNameList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    updateUserStatus(_data) { //用户冻结-解封
        return request({
            url: '/api/user/updateUserStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 订单接口

    getShopBillDetails(_data) { //查询订单详情
        return request({
            url: '/api/shopbill/getShopBillDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getShopBillList(_data) { //订单列表
        return request({
            url: '/api/shopbill/getShopBillList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    classDownloadFile(_data) { //导出订单
        return request({
            url: '/api/shopbill/exportOrderListTemplate?data=' + JSON.stringify(_data),
            method: 'post',
            responseType: 'blob'
        })
    },

    // 车辆管理接口

    getUserCarList(_data) { //车辆列表
        return request({
            url: '/api/car/getUserCarList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    exportTemplate(_data) { //导入车辆模板
        return request({
            url: '/api/car/exportTemplate?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },
    // 广告接口

    bannerList(_data) { //轮播图列表
        return request({
            url: '/system/banner/bannerList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    bannerUpdate(_data) { //轮播图修改-添加
        return request({
            url: '/system/banner/bannerUpdate?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    getBannerIconList(_data) { //金刚区icon
        return request({
            url: '/system/banner/getBannerIconList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    bannerDelete(_data) { //轮播图删除接口
        return request({
            url: '/system/banner/bannerDelete?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    bannerDelete(_data) { //轮播图删除接口
        return request({
            url: '/system/banner/bannerDelete?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 合作商接口

    getPartnerList(_data) { //合作商列表
        return request({
            url: '/system/partner/getPartnerList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    getPartnerDetails(_data) { //合作商详情
        return request({
            url: '/system/partner/getPartnerDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    freezeAndRestorePartner(_data) { //合作商冻结/恢复
        return request({
            url: '/system/partner/freezeAndRestorePartner?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    partnerInsertAndUpdate(_data) { //合作商添加-修改
        return request({
            url: '/system/partner/partnerInsertAndUpdate?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 管理员管理接口

    adminList(_data) { //管理员列表
        return request({
            url: '/api/adminCenter/adminList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    adminDelete(_data) { //删除
        return request({
            url: '/api/adminCenter/delete?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    adminAdd(_data) { //添加
        return request({
            url: '/api/adminCenter/insert?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    adminUpdate(_data) { //编辑
        return request({
            url: '/api/adminCenter/update?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 角色管理

    createRole(_data) { //添加角色
        return request({
            url: '/api/gkAdminRole/createRole?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    distributionPermissionForRole(_data) { //分配角色权限
        return request({
            url: '/api/gkAdminRole/distributionPermissionForRole?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    editRole(_data) { //编辑角色
        return request({
            url: '/api/gkAdminRole/editRole?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    getBindingPermission(_data) { //获取角色已绑定权限
        return request({
            url: '/api/gkAdminRole/getBindingPermission?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    getLogList(_data) { //操作日志
        return request({
            url: '/api/gkAdminRole/getLogList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    removeRole(_data) { //删除角色
        return request({
            url: '/api/gkAdminRole/removeRole?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },

    roleList(_data) { //角色列表
        return request({
            url: '/api/gkAdminRole/roleList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },



    // 权限管理接口

    getAdminWithTheMenuTree() { //获取用户拥有的菜单树
        return request({
            url: '/api/permission/getAdminWithTheMenuTree',
            method: 'get',
        })
    },

    getMenuTree() { //获取菜单树
        return request({
            url: '/api/permission/getMenuTree',
            method: 'get',
        })
    },

    //门店服务管理
    initAccountPage(_data) { //门店服务列表
        return request({
            url: '/api/shopservicemendian/getShopDataServiceExamineList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    shopservicemendianGetShopServiceMendianReviewDetails(_data) { //门店服务详情
        return request({
            url: '/api/shopservicemendian/getShopServiceMendianReviewDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },

    shopservicemendianUpdateShopServiceMendianStatus(_data) { //审核门店服务
        return request({
            url: '/api/shopservicemendian/updateShopServiceMendianStatus?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },


    // 小程序首页跳转接口
    articleGetIndexServiceList(_data) { //查看
        return request({
            url: '/api/article/getIndexServiceList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    articleUpdateIndexService(_data) { //编辑
        return request({
            url: '/api/article/updateIndexService?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    // 售卡数据
    sellingCardsStatistics(_data) { //查看
        return request({
            url: '/api/statistics/sellingCardsStatistics?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 门店售卡数据详情
    sellingCardsStatisticsDetail(_data) { //查看
        return request({
            url: '/api/statistics/sellingCardsStatisticsDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 售卡统计详情业务员列表
    sellingCardsStaffList(_data) { //查看
        return request({
            url: '/api/statistics/sellingCardsStaffList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 用户会员卡详情
    getAdminVipCardDetails(_data) { //查看
        return request({
            url: '/api/user/getAdminVipCardDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 用户会员卡详情
    getAdminUserVipDetails(_data) { //查看
        return request({
            url: '/api/user/getAdminUserVipDetails?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 用户会员卡详情
    getVipDetail(_data) { //查看
        return request({
            url: '/api/vip/getVipDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    // 导出门店售卡统计
    exportSellingCardsStatisticsListTemplate(_data) {
        return request({
            url: '/api/statistics/exportSellingCardsStatisticsListTemplate?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },
    // 导出门店售卡统计
    exportShopStaffAllListTemplate(_data) {
        return request({
            url: '/api/staff/exportShopStaffAllListTemplate?data=' + JSON.stringify(_data),
            method: 'get',
            responseType: 'blob'
        })
    },
}