import { createRouter, createWebHashHistory } from 'vue-router'

export const routes = [
  { 
      path: "/login", 
      name: 'login',
      meta:{ notoken: true},
      component: () => import('@/views/login/login.vue')
  },
  { 
      path: "/password", 
      name: 'password',
      meta:{ notoken: true},
      notoken: true,
      component: () => import('@/views/login/password.vue')
  },
  { 
    path: "/",
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/", 
        name: 'datas',
        component: () => import('@/views/datas/index.vue'),
        meta:{ titL: ['数据统计'] , tab:'/', keepAlive: true  },
      },
      { 
        path: "/sellcard", 
        name: 'sellcard',
        component: () => import('@/views/datas/sellcard.vue'),
        meta:{ titL: ['门店售卡数据'] , tab:'/sellcard' },
      },
      { 
        path: "/sellcardshop/:id", 
        name: 'sellcardshop',
        component: () => import('@/views/datas/sellcardshop.vue'),
        meta:{ titL: ['店铺售卡详情'] , tab:'/sellcard' },
      },
    
    ]
  },
  { 
    path: "/", 
    title: '用户管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/users", 
        name: 'users',
        component: () => import('@/views/users/index.vue'),
        meta:{ titL: ['用户管理'] , tab:'/users', keepAlive: true },
      },
      { 
        path: "/userInfo/:id", 
        name: 'userInfo',
        component: () => import('@/views/users/info.vue'),
        meta:{ titL: ['用户详情'] , tab:'/users' },
      },
      { 
        path: "/cardInfo/:id", 
        name: 'cardInfo',
        component: () => import('@/views/users/cardInfo.vue'),
        meta:{ titL: ['用户会员卡详情'] , tab:'/users' },
      },
    ]
  },
  { 
    path: "/", 
    title: '导入会员',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/member", 
        name: 'member',
        component: () => import('@/views/member/index.vue'),
        meta:{ titL: ['导入会员'] , tab:'/member', keepAlive: true },
      },
    ]
  },
  { 
    path: "/", 
    title: '车辆管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/cars", 
        name: 'cars',
        component: () => import('@/views/cars/index.vue'),
        meta:{ titL: ['车辆管理'] , tab:'/cars', keepAlive: true },
      },
      { 
        path: "/carInfo/:id", 
        name: 'carInfo',
        component: () => import('@/views/users/info.vue'),
        meta:{ titL: ['用户详情'] , tab:'/cars' },
      },
    ]
  },
  { 
    path: "/", 
    title: '业务员管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/salesman", 
        name: 'salesman',
        component: () => import('@/views/salesman/index.vue'),
        meta:{ titL: ['业务员管理'] , tab:'/salesman' },
      },
      { 
        path: "/salesmanInfo/:id", 
        name: 'salesmanInfo',
        component: () => import('@/views/salesman/Info.vue'),
        meta:{ titL: ['业务员详情'] , tab:'/salesman' },
      },
    ]
  },
  { 
    path: "/", 
    title: '会员卡管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/cards", 
        name: 'cards',
        component: () => import('@/views/cards/index.vue'),
        meta:{ titL: ['会员卡管理'] , tab:'/cards' },
      },
      { 
        path: "/cardAdd", 
        name: 'cardAdd',
        component: () => import('@/views/cards/add.vue'),
        meta:{ titL: ['添加会员卡'] , tab:'/cards' },
      },
      { 
        path: "/cardsInfo/:id", 
        name: 'cardsInfo',
        component: () => import('@/views/cards/info.vue'),
        meta:{ titL: ['会员卡详情'] , tab:'/cards' },
      },
      { 
        path: "/cardEdit/:id", 
        name: 'cardEdit',
        component: () => import('@/views/cards/edit.vue'),
        meta:{ titL: ['修改会员卡'] , tab:'/cards' },
      },
      { 
        path: "/scretGenerate", 
        name: 'scretGenerate',
        component: () => import('@/views/cards/scretGenerate.vue'),
        meta:{ titL: ['生成卡密'] , tab:'/cards' },
      },
      { 
        path: "/scretList", 
        name: 'scretList',
        component: () => import('@/views/cards/scretList.vue'),
        meta:{ titL: ['卡号卡密详情'] , tab:'/cards' },
      },
    ]
  },
  { 
    path: "/", 
    title: '服务管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/service", 
        name: 'service',
        component: () => import('@/views/service/index.vue'),
        meta:{ titL: ['服务管理'] , tab:'/service' },
      },
      { 
        path: "/serviceEdit/:id", 
        name: 'serviceEdit',
        component: () => import('@/views/service/add.vue'),
        meta:{ titL: ['添加服务'] , tab:'/service' },
      },
    ]
  },
  { 
    path: "/", 
    title: '洗车机管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: "/carWasherList", 
        name: 'carWasherList',
        component: () => import('@/views/carWasher/list.vue'),
        meta:{ titL: ['洗车机管理'] , tab:'/carWasherList' },
      },
      {
        path: "/carWasherAdd", 
        name: 'carWasherAdd',
        component: () => import('@/views/carWasher/add.vue'),
        meta:{ titL: ['添加洗车机'] , tab:'/carWasherList' },
      },
      {
        path: "/carWasherEdit", 
        name: 'carWasherEdit',
        component: () => import('@/views/carWasher/edit.vue'),
        meta:{ titL: ['编辑洗车机'] , tab:'/carWasherEdit' },
      },
      { 
        path: "/carWasherInfo",
        name: 'carWasherInfo',
        component: () => import('@/views/carWasher/info.vue'),
        meta:{ titL: ['洗车机详情'] , tab:'/carWasherList' },
      },
    ]
  },
  { 
    path: "/",
    title: '优惠券',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/couponList", 
        name: 'couponList',
        component: () => import('@/views/coupon/list.vue'),
        meta:{ titL: ['优惠券'] , tab:'/couponList'},
      },
      { 
        path: "/couponAdd", 
        name: 'couponAdd',
        component: () => import('@/views/coupon/add.vue'),
        meta:{ titL: ['添加优惠券'] , tab:'/couponList'},
      },
      { 
        path: "/couponEdit", 
        name: 'couponEdit',
        component: () => import('@/views/coupon/edit.vue'),
        meta:{ titL: ['优惠券编辑'] , tab:'/couponList'},
      },
      { 
        path: "/couponInfo", 
        name: 'couponInfo',
        component: () => import('@/views/coupon/info.vue'),
        meta:{ titL: ['优惠券详情'] , tab:'/couponList'},
      },
      { 
        path: "/ancouponList", 
        name: 'ancouponList',
        component: () => import('@/views/coupon/anlist.vue'),
        meta:{ titL: ['开单券'] , tab:'/ancouponList'},
      },
      { 
        path: "/ancouponAdd", 
        name: 'ancouponAdd',
        component: () => import('@/views/coupon/anadd.vue'),
        meta:{ titL: ['添加开单券'] , tab:'/ancouponList'},
      },
      { 
        path: "/ancouponInfo", 
        name: 'ancouponInfo',
        component: () => import('@/views/coupon/aninfo.vue'),
        meta:{ titL: ['开单券详情'] , tab:'/ancouponList'},
      },
    ]
  },
  { 
    path: "/", 
    title: '二手车信息',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/oldCarList", 
        name: 'oldCarList',
        component: () => import('@/views/oldcar/list.vue'),
        meta:{ titL: ['二手车信息'] , tab:'/oldCarList' },
      },
      { 
        path: "/oldCarPost", 
        name: 'oldCarPost',
        component: () => import('@/views/oldcar/post.vue'),
        meta:{ titL: ['发布消息'] , tab:'/oldCarList' },
      },
    ]
  },
  { 
    path: "/", 
    title: '门店服务审核',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/storeService", 
        name: 'storeService',
        component: () => import('@/views/storeService/index.vue'),
        meta:{ titL: ['门店服务审核'] , tab:'/storeService' },
      },
      { 
        path: "/serviceInfo/:id", 
        name: 'serviceInfo',
        component: () => import('@/views/storeService/info.vue'),
        meta:{ titL: ['服务审核详情'] , tab:'/storeService' },
      },
    ]
  },
  { 
    path: "/", 
    title: '门店管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/stores", 
        name: 'stores',
        component: () => import('@/views/stores/index.vue'),
        meta:{ titL: ['门店管理'] , tab:'/stores' },
      },
      { 
        path: "/storeAdd", 
        name: 'storeAdd',
        component: () => import('@/views/stores/add.vue'),
        meta:{ titL: ['添加门店'] , tab:'/stores' },
      },
      { 
        path: "/addstaff", 
        name: 'addstaff',
        component: () => import('@/views/stores/addstaff.vue'),
        meta:{ titL: ['添加人员'] , tab:'/stores' },
      },
      { 
        path: "/editstaff", 
        name: 'editstaff',
        component: () => import('@/views/stores/editstaff.vue'),
        meta:{ titL: ['修改人员'] , tab:'/stores' },
      },
      { 
        path: "/storeEdit/:id", 
        name: 'storeEdit',
        component: () => import('@/views/stores/edit.vue'),
        meta:{ titL: ['门店修改'] , tab:'/stores' },
      },
      { 
        path: "/storeInfo", 
        name: 'storeInfo',
        component: () => import('@/views/stores/info.vue'),
        meta:{ titL: ['门店详情'] , tab:'/stores' },
      },
      { 
        path: "/storeExamineInfo/:id", 
        name: 'storeExamineInfo',
        component: () => import('@/views/stores/examineInfo.vue'),
        meta:{ titL: ['门店详情'] , tab:'/stores' },
      },
    ]
  },
  { 
    path: "/", 
    title: '订单',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/order", 
        name: 'order',
        component: () => import('@/views/order/index.vue'),
        meta:{ titL: ['服务订单'] , tab:'/order'},
      },
      { 
        path: "/orderInfo/:id", 
        name: 'orderInfo',
        component: () => import('@/views/order/info.vue'),
        meta:{ titL: ['订单管理详情'] , tab:'/order' },
      },
      { 
        path: "/cardOrder", 
        name: 'cardOrder',
        component: () => import('@/views/order/cardOrder.vue'),
        meta:{ titL: ['会员卡订单'] , tab:'/cardOrder'},
      },
    ]
  },
  {
    path: "/", 
    title: '合作商管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: "/partner", 
        name: 'partner',
        component: () => import('@/views/partner/index.vue'),
        meta:{ titL: ['合作商管理'] , tab:'/partner' },
      },
      { 
        path: "/partnerAdd", 
        name: 'partnerAdd',
        component: () => import('@/views/partner/add.vue'),
        meta:{ titL: ['添加合作商'] , tab:'/partner' },
      },
      { 
        path: "/partnerEdit/:id", 
        name: 'partnerEdit',
        component: () => import('@/views/partner/edit.vue'),
        meta:{ titL: ['修改合作商'] , tab:'/partner' },
      },
      { 
        path: "/partnerInfo/:id", 
        name: 'partnerInfo',
        component: () => import('@/views/partner/info.vue'),
        meta:{ titL: ['合作商详情'] , tab:'/partner' },
      },
    ]
  },
  {
    path: "/", 
    title: '单次洗车券',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: "/singleCarWashL", 
        name: 'singleCarWashL',
        component: () => import('@/views/singleCarWash/list.vue'),
        meta:{ titL: ['单次洗车券'] , tab:'/singleCarWashL'},
      },
      {
        path: "/singleCarWashAdd", 
        name: 'singleCarWashAdd',
        component: () => import('@/views/singleCarWash/add.vue'),
        meta:{ titL: ['单次洗车券','添加企业信息'] , tab:'/singleCarWashAdd' },
      },
      {
        path: "/singleCarWashInfo/:id",
        name: 'singleCarWashInfo',
        component: () => import('@/views/singleCarWash/info.vue'),
        meta:{ titL: ['单次洗车券','企业详情'] , tab:'/singleCarWashInfo' },
      },
      {
        path: "/singleCarWashInfos/:id", 
        name: 'singleCarWashInfos',
        component: () => import('@/views/singleCarWash/infos.vue'),
        meta:{ titL: ['单次洗车券','企业详情','批次详情'] , tab:'/singleCarWashInfo' },
      },
    ]
  },
  { 
    path: "/", 
    title: '消息',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/systemNews", 
        name: 'systemNews',
        component: () => import('@/views/news/systemNews.vue'),
        meta:{ titL: ['系统消息'] , tab:'/systemNews' },
      },
      { 
        path: "/systemNewsInfo", 
        name: 'systemNewsInfo',
        component: () => import('@/views/news/systemNewsInfo.vue'),
        meta:{ titL: ['系统消息','详情'] , tab:'/systemNews' },
      },
      { 
        path: "/issue", 
        name: 'issue',
        component: () => import('@/views/news/issue.vue'),
        meta:{ titL: ['发布消息'] , tab:'/systemNews' },
      },
      { 
        path: "/eCommerceNews", 
        name: 'eCommerceNews',
        component: () => import('@/views/news/eCommerceNews.vue'),
        meta:{ titL: ['预约推送'] , tab:'/eCommerceNews' },
      },
      { 
        path: "/pushEdit", 
        name: 'pushEdit',
        component: () => import('@/views/news/pushEdit.vue'),
        meta:{ titL: ['预约推送','编辑'] , tab:'/eCommerceNews' },
      },
    ]
  },
  { 
    path: "/", 
    title: '分类',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/classify", 
        name: 'classify',
        component: () => import('@/views/classify/index.vue'),
        meta:{ titL: ['服务分类'] , tab:'/classify', keepAlive: true },
      },
    ]
  },
  { 
    path: "/", 
    title: '权限管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/identity", 
        name: 'identity',
        component: () => import('@/views/set/identity.vue'),
        meta:{ titL: ['身份管理'] , tab:'/identity', keepAlive: true },
      },
      { 
        path: "/manager", 
        name: 'manager',
        component: () => import('@/views/set/manager.vue'),
        meta:{ titL: ['管理员管理'] , tab:'/manager', keepAlive: true },
      },
    ]
  },
  { 
    path: "/", 
    title: '设置',
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/operationLog", 
        name: 'operationLog',
        component: () => import('@/views/set/operationLog.vue'),
        meta:{ titL: ['操作日志'] , tab:'/operationLog' },
      },
      { 
        path: "/article", 
        name: 'article',
        component: () => import('@/views/set/article.vue'),
        meta:{ titL: ['文章管理'] , tab:'/article' },
      },
      { 
        path: "/articleEdit", 
        name: 'articleEdit',
        component: () => import('@/views/set/articleEdit.vue'),
        meta:{ titL: ['编辑文章'] , tab:'/article'  },
      },
      { 
        path: "/advertisement", 
        name: 'advertisement',
        component: () => import('@/views/set/advertisement.vue'),
        meta:{ titL: ['广告管理'] , tab:'/advertisement' },
      },
      { 
        path: "/advertisementInfo/:id", 
        name: 'advertisementInfo',
        component: () => import('@/views/set/advertisementInfo.vue'),
        meta:{ titL: ['编辑广告'] , tab:'/advertisement' },
      },
      { 
        path: "/indexSet", 
        name: 'indexSet',
        component: () => import('@/views/set/indexSet.vue'),
        meta:{ titL: ['首页服务导航'] , tab:'/article'  },
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
