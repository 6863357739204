import { createStore } from 'vuex'

import utils from '../js/utils.js'

export default createStore({
  state: {
    globalUrl: 'https://app.xiangmaitech.cn',
    requestType: 'java',  //后台语言 php、java
    // token:localStorage.getItem('token') ? localStorage.getItem('token'):'',
    token: utils.getLocal('token') ,
    adminId: utils.getLocal('adminId'),
    info: utils.getLocal('info','json'),
    menuList: utils.getLocal('menuList','json'),
    routeCurrent: utils.getLocal('routeCurrent'),
  },
  getters: {
  },
  mutations: {
    setToken(state, value) { // 设置存储token
			state.token = value;
			localStorage.setItem('token', value);
		},
    setAdminId(state, value) { // 设置存储adminId
			state.adminId = value;
			localStorage.setItem('adminId', value);
		},
    setInfo(state, value) { // 设置存储账户详情
			state.info = value;
			localStorage.setItem('info', JSON.stringify(value));
		},
    setMenuList(state, value) { // 设置存储侧栏数据
			state.menuList = value;
			localStorage.setItem('menuList', JSON.stringify(value));
		},
    setRouteCurrent(state, value) { // 设置存储路由路径
			state.routeCurrent = value;
			localStorage.setItem('routeCurrent', value);
		},
   
  },
  actions: {
  },
  modules: {
  }
})
